import React, { useState, useEffect, useContext } from "react";
import {
  Avatar,
  useMediaQuery,
  useTheme,
  CardHeader,
  Box,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import ArrowLeftIcon from "../../assets/icon/ArrowLeftIcon";
import { i18n } from "../../translate/i18n";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TicketsContext } from "../../context/Tickets/TicketsContext";


const useStyles = makeStyles((theme) => ({
  avatarBox: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginLeft: -20,
    },
  },
  avatar: {
    pointerEvents: 'none',
  },
}));


const TicketInfo = ({ contact, ticket, onClick, setOption }) => {
  const classes = useStyles();
  const { user } = ticket;
  const [userName, setUserName] = useState("");
  const [contactName, setContactName] = useState("");
  const theme = useTheme();
  const history = useHistory();
  const isMdOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const { currentTicket, setCurrentTicket } = useContext(TicketsContext);

  useEffect(() => {
    if (contact) {
      setContactName(contact.name);
      if (document.body.offsetWidth < 600) {
        if (contact.name.length > 10) {
          const truncadName = contact.name.substring(0, 10) + "...";
          setContactName(truncadName);
        }
      }
    }

    if (user && contact) {
      setUserName(`${i18n.t("messagesList.header.assignedTo")} ${user.name}`);

      if (document.body.offsetWidth < 600) {
        setUserName(`${user.name}`);
      }
    }
  }, [contact, user]);

  const handleBackClick = (event) => {
    event.stopPropagation();
    setCurrentTicket({ id: null, code: null });
    setOption(1);
  };
  return (
    <CardHeader
      onClick={onClick}
      style={{ cursor: "pointer" }}
      titleTypographyProps={{ noWrap: true }}
      subheaderTypographyProps={{ noWrap: true, style: { color: "#959595" } }}
      avatar={
        <Box className={classes.avatarBox}>
          {isMdOrSmaller && (
            <IconButton onClick={handleBackClick}>
              <ArrowLeftIcon fill="#AEADAC" />
            </IconButton>
          )}
          <Avatar
            src={contact.profilePicUrl}
            alt="contact_image"
            className={classes.avatar}
          />
        </Box>
      }
      title={`${contactName}`}
      subheader={ticket.user && `${userName}`}
    />
  );
};

export default TicketInfo;