import React, { useState } from "react";
import ArrowDownIcon from "../../assets/icon/ArrowDownIcon";
import { Button, Checkbox, List, ListItem, ListItemText, makeStyles, Popover, useTheme } from "@material-ui/core";
import DownArrow from "../../assets/icon/DownArrow";

const useStyles = makeStyles((theme) => ({
	button: {
	  textTransform: 'none',
	  justifyContent: 'space-between',
	  padding: theme.spacing(1, 2),
	  fontSize: 18,
	  fontWeight: 500,
	  width: 180,
	  border:"none",
	  '& .MuiButton-endIcon': {
		marginLeft: theme.spacing(1),
	  },
	  [theme.breakpoints.down('lg')]: {
		fontSize: 16,
		width: '100%',
		padding: theme.spacing(1, 1),
	  },
	  [theme.breakpoints.down('md')]: {
		fontSize: 18,
		width: '100%',
		padding: theme.spacing(1, 2),
	  },
	  [theme.breakpoints.down('sm')]: {
		fontSize: 18,
		width: '100%',
		padding: theme.spacing(1, 1),
	  },
	  [theme.breakpoints.down('xs')]: {
		fontSize: 14,
	  },
	},
	popover: {
	  width: 180,
	  [theme.breakpoints.down('sm')]: {
		width: '90%',
		maxWidth: 300,
	  },
	},
	list: {
	  width: '100%',
	},
	listItem: {
		border:"none",
	  padding: theme.spacing(1, 2),
	},
  }));
  
  const CustomSelect = ({ userQueues, selectedQueueIds = [], onChange }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const theme = useTheme();
	const classes = useStyles();
  
	const handleClick = (event) => {
	  setAnchorEl(event.currentTarget);
	};
  
	const handleClose = () => {
	  setAnchorEl(null);
	};
  
	const handleOptionClick = (queueId) => {
	  const newSelectedQueues = selectedQueueIds.includes(queueId)
		? selectedQueueIds.filter((id) => id !== queueId)
		: [...selectedQueueIds, queueId];
  
	  onChange(newSelectedQueues);
	};
  
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
  
	return (
	  <div>
		<Button
		  aria-describedby={id}
		  variant="outlined"
		  onClick={handleClick}
		  endIcon={<DownArrow fill={theme.palette.messageIcons} />}
		  className={classes.button}
		>
		  Departamentos
		</Button>
		<Popover
		  id={id}
		  open={open}
		  anchorEl={anchorEl}
		  onClose={handleClose}
		  anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'left',
		  }}
		  transformOrigin={{
			vertical: 'top',
			horizontal: 'left',
		  }}
		  classes={{
			paper: classes.popover,
		  }}
		>
		  <List className={classes.list}>
			{userQueues?.length > 0 &&
			  userQueues.map((queue) => (
				<ListItem
				  key={queue.id}
				  onClick={() => handleOptionClick(queue.id)}
				  button
				  dense
				  className={classes.listItem}
				>
				  <Checkbox
					edge="start"
					checked={selectedQueueIds.includes(queue.id)}
					tabIndex={-1}
					disableRipple
				  />
				  <ListItemText primary={queue.name} />
				</ListItem>
			  ))}
		  </List>
		</Popover>
	  </div>
	);
  };
  
  export default CustomSelect;