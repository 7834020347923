import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import { IconButton, Tooltip, useTheme } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import { v4 as uuidv4 } from "uuid";

import VisibilityIcon from "@material-ui/icons/Visibility";
import TicketMessagesDialog from "../TicketMessagesDialog";
import DoneIcon from '@material-ui/icons/Done';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { toast } from "react-toastify";
import EyeIcon from "../../assets/icon/EyeIcon";
import ErrorIcon from "../../assets/icon/ErrorIcon";
import SuccessIcon from "../../assets/icon/SuccessIcon";
import TransferTicketModalCustom from "../TransferTicketModalCustom";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
    height: "auto",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 3, 1, 3),
  },
  contactNameWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,

  },
  contactName: {
    fontWeight: 600,
    fontSize: 16,
  },
  contactLastMessage: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
  badgesWrapper: {
    display: "flex",
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  badgeStyle: {
    borderRadius: "12px",
    padding: "4px 8px",
    fontSize: "12px",
    color: "#fff",
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
  actionsWrapper: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginLeft: "auto",
  },
  iconButton: {
    padding: theme.spacing(0.5),
    borderRadius: "50%",
  },
  badge: {
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.001),
  }
}));


const TicketListItemCustom = ({ ticket, setUpdate, isSelected, reloadTickets }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
  const { setCurrentTicket } = useContext(TicketsContext);
  const { user } = useContext(AuthContext);
  const { profile } = user;

  const handleSelectTicket = (ticket) => {
    const code = uuidv4();
    setCurrentTicket({ id: ticket.id, uuid: ticket.uuid, code });
  };

  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.slice(0, limit) + "...";
    }
    return text;
  };

  const addOpacityToColor = (hexColor, opacity) => {
    const rgbaColor = hexColor
      .replace("#", "")
      .match(/.{1,2}/g)
      .map((x) => parseInt(x, 16));
    return `rgba(${rgbaColor[0]}, ${rgbaColor[1]}, ${rgbaColor[2]}, ${opacity})`;
  };

  const adjustColorVisibility = (hexColor, factor) => {
    const rgb = hexColor.replace("#", "").match(/.{2}/g).map(x => parseInt(x, 16));
    const adjustedRgb = rgb.map(channel => {
      const adjusted = Math.round(channel * factor);
      return Math.min(255, Math.max(0, adjusted));
    });
    return `rgb(${adjustedRgb.join(", ")})`;
  };
  function capitalizeFirstLetter(string) {
    return string && typeof string === 'string' 
      ? string.charAt(0).toUpperCase() + string.slice(1) 
      : '';
  }

  const handleCloseTicket = async (id) => {
    setLoading(true);
    try {
      const { data } = await api.put(`/tickets/${id}`, {
        status: "closed",
        justClose: true,
        userId: user?.id,
      });
      console.log("data", data)
      setLoading(false);
      setUpdate(Math.random());
      toast.success("Se ha cerrado el chat correctamente");
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleAcepptTicket = async (id) => {
    setLoading(true);
    try {
      const { data } = await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });
      console.log("data", data)
      setLoading(false);
      setUpdate(Math.random());
      toast.success("Se ha aceptado el chat correctamente");
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const renderBadges = () => (
    <div className={classes.badgesWrapper}>
      {ticket?.whatsappId && ticket?.whatsapp?.name && (
        <div className={classes.badgeStyle}
          style={{
            backgroundColor: addOpacityToColor("#4C7FFF29", 0.2),
            color: adjustColorVisibility("#4C7FFF29", 1),
            fontWeight: 600
          }}>
          {capitalizeFirstLetter(ticket.whatsapp.name)}
        </div>
      )}
      {ticket?.queue?.name && (
        <div className={classes.badgeStyle}
          style={{
            backgroundColor: addOpacityToColor(ticket.queue?.color || "#7C7C7C", 0.2),
            color: adjustColorVisibility(ticket.queue?.color || "#7C7C7C", 1),
            fontWeight: 600
          }}>
          {capitalizeFirstLetter(ticket.queue.name)}
        </div>
      )}
    </div>
  );

  const renderActions = () => (
    <div className={classes.actionsWrapper}>
      {ticket.status === "pending" && (
        <Tooltip title="Aceptar conversación">
          <IconButton
            className={classes.iconButton}
            onClick={() => handleAcepptTicket(ticket.id)}
          >
            <SuccessIcon fill={"#5BC64C"}  width="22" height="22" />
          </IconButton>
        </Tooltip>
      )}
      {profile === "admin" && (
        <Tooltip title="Ver conversación">
          <IconButton
            className={classes.iconButton}
            onClick={() => setOpenTicketMessageDialog(true)}
          >
            <EyeIcon fill={theme.palette.messageIcons} width="22" height="22" />
          </IconButton>
        </Tooltip>
      )}
      {ticket.status === "open" && (
        <Tooltip title="Rechazar conversación">
          <IconButton
            className={classes.iconButton}
            onClick={() => handleCloseTicket(ticket.id)}
          >
            <ErrorIcon fill={"#FF5858"}  width="22" height="22" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );

  return (
    <>
      <TransferTicketModalCustom 
        modalOpen={openTicketMessageDialog}
        onClose={() => setOpenTicketMessageDialog(false)}
        ticketId={ticket?.id}
        reloadTickets={reloadTickets}
      />
      <TicketMessagesDialog
        open={openTicketMessageDialog}
        handleClose={() => setOpenTicketMessageDialog(false)}
        ticketId={ticket?.id}
      />
      <ListItem
        button
        className={classes.ticket}
        onClick={() => ticket?.status !== "pending" && handleSelectTicket(ticket)}
        selected={isSelected}
      >
        <ListItemAvatar>
          <Avatar src={ticket?.contact?.profilePicUrl} className={classes.avatar} />
        </ListItemAvatar>
        <div className={classes.contactNameWrapper}>
          <Typography className={classes.contactName}>
            {ticket?.contact?.name}
          </Typography>
          <Typography className={classes.contactLastMessage} noWrap>
            {truncateText(ticket?.lastMessage || '', 23)}
          </Typography>
          {renderBadges()}
        </div>
        <Badge className={classes.badge} badgeContent={ticket.unreadMessages} color="primary"></Badge>
        {renderActions()}
      </ListItem>
      <Divider style={{ opacity: 0.3 }} />
    </>
  );
};

export default TicketListItemCustom;
