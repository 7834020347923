import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { has, isArray } from "lodash";

import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";
import api, { apione, openApi } from "../../services/api";
import toastError from "../../errors/toastError";
import { socketConnection } from "../../services/socket";
import moment from "moment";
const useAuth = () => {
  const history = useHistory();
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  openApi.interceptors.request.use(
    (config) => {
      if (config.includeToken !== false) {
        const token = localStorage.getItem("token");
        if (token) {
          let tokenClean = token.replace(/"/g, '');
          config.headers["Authorization"] = `Bearer ${tokenClean}`;
          setIsAuth(true);
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        let tokenClean = token.replace(/"/g, '');
        config.headers["Authorization"] = `Bearer ${tokenClean}`;
        setIsAuth(true);
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;

        const { data } = await openApi.post("/auth/refresh_token");
        if (data) {
          localStorage.setItem("token", JSON.stringify(data.token));
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
        }
        return api(originalRequest);
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("companyId");
        api.defaults.headers.Authorization = undefined;
        setIsAuth(false);
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    (async () => {
      if (token) {
        try {
          const { data } = await api.post("/auth/refresh_token");
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
          setIsAuth(true);
          setUser(data.user);
        } catch (err) {
          console.log(err)
        }
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    if (!user.languageId) {
      localStorage.removeItem("i18nextLng");
    } else {
      const language = user.languageId === 2 ? "es" : "en";
      localStorage.setItem("i18nextLng", language);
      i18n.changeLanguage(language);
    } 

    socket.on(`company-${companyId}-user`, (data) => {
      if (data.action === "update" && data.user.id === user.id) {
        setUser(data.user);
      }
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleLogin = async (userData) => {
    setLoading(true);

    try {
      const { data } = await openApi.post("/auth/login", userData);
      const {
        user: { companyId, id, company, languageId },
      } = data;

      if (has(company, "settings") && isArray(company.settings)) {
        const setting = company.settings.find(
          (s) => s.key === "campaignsEnabled"
        );
        if (setting && setting.value === "true") {
          localStorage.setItem("cshow", null);
        }
      }

      moment.locale('pt-br');
      console.log("user", data.user)
      const dueDate = data.user.company.dueDate;
      const hoje = moment(moment()).format("DD/MM/yyyy");
      const vencimento = moment(dueDate).format("DD/MM/yyyy");  
      var diff = moment(dueDate).diff(moment(moment()).format());
      console.log("hoje",hoje)
      console.log("vencimento",vencimento)
      var before = moment(moment().format()).isBefore(dueDate);
      console.log("before",before)
      var dias = moment.duration(diff).asDays();
      var diasVenc = vencimento.valueOf() - hoje.valueOf()
      console.log("🚀 Console Log : diasVenc", diasVenc);

      if (before === true) {
        localStorage.setItem("token", JSON.stringify(data.token));
        localStorage.setItem("companyId", companyId);
        localStorage.setItem("userId", id);
        localStorage.setItem("companyDueDate", vencimento);
        api.defaults.headers.Authorization = `Bearer ${data.token}`;
        setUser(data.user);
        setIsAuth(true);
        toast.success(i18n.t("auth.toasts.success"));
        if (Math.round(dias) < 5) {
          toast.warn(`Tu suscripción vence en ${Math.round(dias)} ${Math.round(dias) === 1 ? 'día' : 'días'}`);
        }
        history.push("/tickets");
        setLoading(false);
      } else {
        toastError(`¡Vaya! Tu suscripción ha vencido,
          Ponte en contacto con el soporte para obtener más información.`);
        setLoading(false);
      }
  
    } catch (err) {
      setLoading(false);
      console.log(err)
      console.log(err.response)
      if (err.response !== undefined) {
        toastError(err.response.data.error);
      } else if (err) {
        toastError(err);
      } else {
        toastError("¡Vaya! Ocurrió un error inesperado, por favor intente más tarde.");
      }
    }
  };

  const handleLogout = async () => {
    setLoading(true);

    try {
      await api.delete("/auth/logout");
      setIsAuth(false);
      setUser({});
      localStorage.removeItem("token");
      localStorage.removeItem("companyId");
      localStorage.removeItem("userId");
      localStorage.removeItem("cshow");
      api.defaults.headers.Authorization = undefined;
      setLoading(false);
      history.push("/login");
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const getCurrentUserInfo = async () => {
    try {
      const { data } = await api.get("/auth/me");
      return data;
    } catch (err) {
      toastError(err);
    }
  };

  return {
    isAuth,
    user,
    loading,
    handleLogin,
    handleLogout,
    getCurrentUserInfo,
  };
};

export default useAuth;
